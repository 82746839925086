import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import ModalWrapper from "../Modal";
import { Button, ButtonWrapper } from "ds/Button";

const ModalBody = styled.div`
  margin-bottom: 32px;
`;

class ModalConfirmLeaveForm extends React.Component {
  constructor(props) {
    super(props);
    this.modalClassOverride = this.modalClassOverride.bind(this);
    this.overlayClassOverride = this.overlayClassOverride.bind(this);

    this.state = {
      modalCustomClass: "modal-after-open",
      overlayCustomClass: "overlay-after-open"
    };
  }

  modalClassOverride(modalClass) {
    if (modalClass) {
      this.setState({
        modalCustomClass: modalClass + " " + this.state.modalCustomClass
      });
    }
    return this.state.modalCustomClass;
  }

  overlayClassOverride(overlayClass) {
    if (overlayClass) {
      this.setState({
        overlayCustomClass: overlayClass + " " + this.state.overlayCustomClass
      });
    }
    return this.state.overlayCustomClass;
  }

  render() {
    const {
      title = "Are you sure you want to leave?",
      closeModal,
      isOpen,
      continueFunction,
      intl
    } = this.props;
    return (
      <ModalWrapper
        modalClass="modal-small"
        isOpen={isOpen}
        closeModal={closeModal}
        title={title}
      >
        <ModalBody>
          There are unsaved changes in this form that could be lost.
        </ModalBody>
        <ButtonWrapper justifyContent="end">
          <Button
            id="continue-confirm-modal-button"
            variant="secondary"
            onClick={() => {
              continueFunction();
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "continue" })}
          </Button>
          <Button
            id="form-return-modal-button"
            type="submit"
            onClick={() => closeModal()}
          >
            {intl.formatMessage({ id: "return_form" })}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    );
  }
}

ModalConfirmLeaveForm.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  continueFunction: PropTypes.func,
  cancelFunction: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(ModalConfirmLeaveForm);
