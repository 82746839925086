import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";

import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import AnimatedHeaderWithArrow from "Components/AnimatedHeaderWithArrow";
import RadioField from "Components/fields/RadioField";

import * as S from "./styles";

export interface InstanceDropdownProps {
  instances: string[];
  currentInstance: string;
  onChange: (instanceId: string) => void;
}

const PREFIX_ID = "environments-instances";

const InstanceDropdown = ({
  instances,
  currentInstance,
  onChange
}: InstanceDropdownProps) => {
  const [isMenuOpen, openMenu] = useState<boolean>(false);
  const [instanceList, setInstanceList] = useState<string[]>(instances);
  const intl = useIntl();

  const toggleMenu = () => {
    openMenu(!isMenuOpen);
  };

  useEffect(() => {
    setInstanceList(instances);
  }, [instances]);

  const onClick = (value: string) => {
    onChange(value);
  };

  const allLabel = intl.formatMessage({ id: "all_hosts" });
  const hostLabel = intl.formatMessage({ id: "host" });

  return (
    <S.Layout>
      <AnimatedHeaderWithArrow
        prefixId={PREFIX_ID}
        isOpen={isMenuOpen}
        onHeaderClick={toggleMenu}
        arrowClick={true}
        onArrowClick={toggleMenu}
        animate={false}
        ariaLinkLabel={allLabel}
        ariaToggleLabel={intl.formatMessage({ id: "filter_hosts" })}
        className="navbar-link"
        headerPadding="0 8px"
        closeDropDown={() => openMenu(false)}
      >
        <S.CurrentInstance className="text">
          <span className="active" />
          {currentInstance || allLabel}
        </S.CurrentInstance>
      </AnimatedHeaderWithArrow>
      <MenuDropdownWrapper
        // @ts-expect-error
        isOpen={isMenuOpen}
        onClick={e => e.stopPropagation()}
        onKeyUp={e => e.keyCode === 27 && isMenuOpen && toggleMenu()}
      >
        <S.MenuLayout>
          <ul>
            <RadioField
              key="all-instances"
              forId={`all-instances`}
              name="select-all"
              label={allLabel}
              value={!currentInstance}
              onChange={() => onClick("")}
            />
            {instanceList.map(i => (
              <RadioField
                key={`${i}-instance`}
                forId={`${i}-instance`}
                name={`select-${i}`}
                label={`${hostLabel} ${capitalize(i)}`}
                value={currentInstance === i}
                onChange={() => onClick(i)}
              />
            ))}
          </ul>
        </S.MenuLayout>
      </MenuDropdownWrapper>
    </S.Layout>
  );
};

export default InstanceDropdown;
