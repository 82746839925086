import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Map } from "immutable";

import { withReducers } from "Hocs";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { getCSSVarString, ICON } from "Libs/themes";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";
import { loadDeployment } from "Reducers/environment/deployment";

import Tree from "./ServicesTree/ServiceGraph";
import List from "./ServicesList";
import EmptyTree from "./EmptyTree";

import * as S from "./styles";

const isTweenService = service => {
  let [type] = service.type.split(":", 1);
  return type === "varnish";
};

const ServicesDisplay = ({
  hasInfoTooltip,
  hasInstanceSelector = true,
  height,
  listMode,
  mainEnvironmentId,
  maxHeight,
  minHeight,
  strokeColor,
  treePositionY,
  width,
  onLoadEnd
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { appName, environmentId, organizationId, projectId } =
    useDecodedParams();
  const envId = environmentId || mainEnvironmentId;

  const [scrollLeft, setScrollLeft] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [isMouseDown, setMouseDown] = useState(false);
  const [startCursorX, setStartCursorX] = useState(false);
  const [layoutRef, setLayoutRef] = useState(false);

  const environment = useSelectorWithUrlParams(environmentSelector, {
    environmentId: envId
  });
  const isEnvironmentLoading = useSelectorWithUrlParams(
    environmentLoadingSelector,
    {
      environmentId: envId
    }
  );

  const currentDeployment = useSelector(state =>
    state.deployment?.getIn(
      ["data", organizationId, projectId, envId, "current"],
      Map()
    )
  );

  // TODO: Optimize positions
  // TODO: Offload calculations to a web worker
  const serviceGraphTree = useMemo(() => {
    if (!currentDeployment || !currentDeployment.size) {
      return [];
    }

    const cd = currentDeployment?.toJS();
    const t = [];

    const routerTopology = cd.topology?.services["router"];

    //Convert the current deployment API response to service tree graph Tree struct
    const router = {
      id: "router",
      icon: "router",
      label: "Router",
      width: 40,
      height: 40,
      line: 0,
      column: 0,
      iconColor:"var(--icon-slate-fill,var(--icon-slate,var(--slate)))",
      metadata: { ...cd.routes, topology: routerTopology, kind: "router" },
      children: []
    };

    Object.entries(cd?.routes || {}).forEach(([, route]) => {
      if (route.type != "upstream") {
        return;
      }

      let [targetName] = route.upstream.split(":", 1);

      router.children.push(targetName);
    });

    t.push(router);

    // Router is in line 0
    let currentLine = 1;
    // Check if we have a varnish service
    // Varnish is a special case of services that sit between
    // routes and the app.
    const isTweenServices = Object.entries(cd?.services || {}).some(([, s]) =>
      isTweenService(s)
    );
    if (isTweenServices) {
      // We put the app in line 2, service in line 3, worker in line 4
      currentLine++;
    }

    Object.entries(cd?.webapps || {}).forEach(([name, app], i) => {
      let [type] = app.type.split(":", 1);

      const appTopology = cd.topology?.services[name];

      const a = {
        id: name,
        icon: type,
        label: name,
        line: currentLine,
        width: 40,
        height: 40,
        column: i,
        iconColor:"var(--icon-slate-fill,var(--icon-slate,var(--slate)))",
        metadata: {
          appName: name,
          ...app,
          topology: appTopology,
          kind: "app"
        },
        children: []
      };

      Object.entries({ ...app.relationships, ...app.mounts }).forEach(
        ([, endpoint]) => {
          let targetName;
          if (endpoint.source) {
            if (endpoint.source !== "service") {
              return false;
            }

            targetName = endpoint.service;
          } else {
            targetName = endpoint.split(":", 1)[0];
          }

          a.children.push(targetName);
        }
      );

      t.push(a);
    });

    currentLine++;

    Object.entries(cd?.services || {}).forEach(([name, service], i) => {
      let [type] = service.type.split(":", 1);

      const isTween = isTweenService(service);

      const serviceTopology = cd.topology?.services[name];

      const s = {
        id: name,
        label: name,
        icon: type,
        line: isTween ? 1 : currentLine,
        column: isTween ? 0 : i,
        width: 40,
        height: 40,
        iconColor:"var(--icon-slate-fill,var(--icon-slate,var(--slate)))",
        children: [],
        metadata: {
          name: type,
          appName: name,
          ...service,
          topology: serviceTopology,
          kind: "service",
          workers: []
        }
      };

      Object.entries(service.relationships).forEach(([, relationship]) => {
        let [endpointName] = relationship.split(":", 1);
        s.children.push(endpointName);
      });

      Object.entries(cd?.workers).forEach(([workerName, worker]) => {
        Object.entries(worker.relationships).forEach(([, relationship]) => {
          let [endpointName] = relationship.split(":", 1);
          if (endpointName === name) {
            s.children.push(workerName);
            s.metadata.workers.push(worker);
          }
        });
      });

      t.push(s);
    });

    currentLine++;

    Object.entries(cd?.workers).forEach(([name, worker], i) => {
      let [type] = worker.type.split(":", 1);

      const workerTopology = cd.topology?.services[name];

      const w = {
        id: name,
        label: name,
        icon: type,
        line: currentLine,
        column: i,
        width: 40,
        height: 40,
        iconColor:"var(--icon-slate-fill,var(--icon-slate,var(--slate)))",
        children: [],
        metadata: {
          name: type,
          appName: name,
          ...worker,
          topology: workerTopology,
          kind: "worker"
        }
      };

      t.push(w);
    });

    return t;
  }, [currentDeployment]);

  const currentDeploymentError = useSelector(state =>
    state.deployment?.get("errors")
  );

  useEffect(() => {
    if (environment?.data?.has_code) {
      dispatch(loadDeployment(organizationId, projectId, envId));
    }
  }, [environment?.id]);

  useEffect(() => {
    if (!onLoadEnd) return;
    if (
      currentDeploymentError ||
      (!isEnvironmentLoading && !environment?.data?.has_code)
    )
      onLoadEnd();
  }, [currentDeploymentError, isEnvironmentLoading, environment, onLoadEnd]);

  const openNode = node => {
    if (hasInfoTooltip) return;
    goToService(node.metadata);
  };

  const goToService = meta => {
    // appName for worker is format like appName--workerName
    const service = meta.worker
      ? meta.appName.split("--")[0]
      : meta.appName || "routes";
    push(
      `/${organizationId}/${projectId}/${encodeURIComponent(
        envId
      )}/services/${service}`
    );
  };

  const onMouseDown = e => {
    e.preventDefault();
    setStartCursorX(e.clientX);
    setMouseDown(true);
  };

  const onMouseUp = e => {
    e.preventDefault();
    setStartCursorX(false);
    setMouseDown(false);
  };

  const onMouseMove = e => {
    e.preventDefault();
    if (!startCursorX || isMouseDown) {
      return false;
    }

    const endCursorX = e.clientX;
    const scrollLeft = scrollLeft + (startCursorX - endCursorX);
    setStartCursorX(endCursorX);
    layoutRef.scrollLeft = scrollLeft;
  };

  const onMouseOut = e => {
    e.preventDefault();
    onMouseMove(e);
    onMouseUp(e);
  };

  if (currentDeploymentError) {
    return (
      <EmptyTree>
        <FormattedMessage id="services.display.error" />
      </EmptyTree>
    );
  }

  if (!isEnvironmentLoading && !environment?.data?.has_code) {
    return (
      <EmptyTree>
        <FormattedMessage id="services.display.empty" />
      </EmptyTree>
    );
  }

  return (
    <S.Layout
      onScroll={e => setScrollLeft(e.target.scrollLeft)}
      onMouseUp={e => onMouseUp(e)}
      onMouseDown={e => onMouseDown(e)}
      onMouseMove={e => onMouseMove(e)}
      onMouseOut={e => onMouseOut(e)}
      ref={ref => setLayoutRef(ref)}
      dragged={isMouseDown}
      onBlur={e => onMouseOut(e)}
      listMode={listMode}
      isScroll={isScroll}
    >
      {listMode ? (
        <List
          onClick={node => openNode(node)}
          currentDeployment={currentDeployment.toJS()}
          search={""}
          selected={appName || "routes"}
        />
      ) : (
        <Tree
          data={serviceGraphTree}
          onClick={node => openNode(node)}
          goToService={goToService}
          instances={currentDeployment.get("instances")}
          strokeColor={strokeColor}
          onNeedScroll={needScroll => setIsScroll(needScroll)}
          treePositionY={treePositionY}
          leftOffset={scrollLeft}
          height={height}
          width={width}
          minHeight={minHeight}
          maxHeight={maxHeight}
          hasInfoTooltip={hasInfoTooltip}
          hasInstanceSelector={hasInstanceSelector}
          onLoadEnd={onLoadEnd}
        />
      )}
    </S.Layout>
  );
};

ServicesDisplay.propTypes = {
  hasInfoTooltip: PropTypes.bool,
  height: PropTypes.string,
  listMode: PropTypes.bool,
  mainEnvironmentId: PropTypes.string,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.string,
  strokeColor: PropTypes.string,
  treePositionY: PropTypes.number,
  width: PropTypes.string,
  onLoadEnd: PropTypes.func,
  hasInstanceSelector: PropTypes.bool
};

export default withReducers({
  deployment: () => import("Reducers/environment/deployment"),
  environment: () => import("Reducers/environment")
})(ServicesDisplay);
