import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import LabeledInfo from "Components/LabeledInfo";

const AppInfos = ({ metadata, multiInstance = false }) => {
  const intl = useIntl();

  const instanceCount = metadata?.topology?.instances?.length;

  return (
    <>
      <LabeledInfo
        gridColumn="auto"
        label={intl.formatMessage({ id: "version" })}
        value={
          metadata.type.split(":")[1] || intl.formatMessage({ id: "unknown" })
        }
      />
      <LabeledInfo
        gridColumn="auto"
        label={intl.formatMessage({ id: "size" })}
        value={metadata.size || intl.formatMessage({ id: "unknown" })}
      />
      <LabeledInfo
        gridColumn="auto"
        label={intl.formatMessage({ id: "disk" })}
        value={metadata.disk || intl.formatMessage({ id: "unknown" })}
      />
      <LabeledInfo
        gridColumn="auto"
        label={intl.formatMessage({ id: "crons" })}
        value={metadata.crons ? Object.keys(metadata.crons).length : 0}
      />
      {multiInstance && !!instanceCount && (
        <LabeledInfo
          gridColumn="auto"
          label={intl.formatMessage({ id: "instances" })}
          value={instanceCount}
        />
      )}
    </>
  );
};

AppInfos.propTypes = {
  metadata: PropTypes.object,
  multiInstance: PropTypes.bool
};

export default AppInfos;
