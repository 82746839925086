import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";

import * as ProviderLogos from "Components/icons/providers/index";
import LeafIcon from "Components/icons/Leaf";
import * as S from "./EnvironmentImpact.styles";

const EnvironmentImpact = ({ inModal = false, region }) => {
  const intl = useIntl();

  const ProviderLogo = useMemo(() => {
    const name = region?.data?.provider?.name
      ? capitalize(region.data.provider.name.toLowerCase())
      : "";
    return ProviderLogos[name];
  }, [region?.data?.provider?.name]);

  return (
    <S.Wrapper>
      {!inModal && (
        <S.Icon>
          <LeafIcon />
        </S.Icon>
      )}
      {region?.data?.provider ? (
        <S.Details>
          <S.Logo>
            {ProviderLogo ? (
              <ProviderLogo />
            ) : (
              <img
                src={`data:image/svg+xml;base64,${region.data.provider.logo}`}
                alt={region.data.provider.name}
              />
            )}
          </S.Logo>

          <>
            {intl.formatMessage(
              { id: "environment_impact.carbon_intensity" },
              {
                carbonIntensity:
                  region.data?.environmental_impact?.carbon_intensity
              }
            )}
          </>
          <S.Label>
            {intl.formatMessage({ id: "environment_impact.label" })}
          </S.Label>
          <>{region.data?.datacenter?.location}</>
        </S.Details>
      ) : (
        <S.Default>
          {intl.formatMessage({ id: "environment_impact.default" })}
        </S.Default>
      )}
    </S.Wrapper>
  );
};

EnvironmentImpact.propTypes = {
  inModal: PropTypes.bool,
  region: PropTypes.object
};

export default EnvironmentImpact;
