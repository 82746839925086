import { Edge as GraphlibEdge } from "graphlib";
import { PshGraph, TreeOptions, GGroup } from "./";

const d3 = require("d3");

let ID_DELIM = /:/g;
function escapeId(str: string) {
  return str ? str.replace(ID_DELIM, "\\:") : "";
}

function edgeToId(e: GraphlibEdge): string {
  if (e.name) {
    return `${escapeId(e.v)}:${escapeId(e.w)}:${escapeId(e.name)}`;
  }

  return `${escapeId(e.v)}:${escapeId(e.w)}`;
}

const generatePath =
  (g: PshGraph, options: TreeOptions) => (e: GraphlibEdge) => {
    let node = g.node(e.v);
    let edge = g.edge(e);
    let target = g.node(e.w);

    let points = edge.points;

    if (!points) {
      points = [0, 1];
    }

    points[0] = { x: node.x, y: node.y };
    points[points.length - 1] = { x: target.x, y: target.y };

    let path = new d3.path();
    let nodeRadius = node.width / 2 + 5;

    if (edge.class.includes("same")) {
      // Ensure proper edge orientation with target right of node
      if (node.column > target.column) {
        points.reverse();
      }

      // Connections in the same layer.
      if (Math.abs(node.column - target.column) === 1) {
        // Side by side nodes: connect directly.
        let directionX = points[1].x > points[0].x ? 1 : -1;

        path.moveTo(points[0].x + directionX * nodeRadius, points[0].y);
        path.lineTo(points[1].x - directionX * nodeRadius, points[1].y);
      } else {
        // Else, connect through the bottom, with offset depending on # of layers
        const sameLayerOffset = options.midLayerOffset - options.edgeMargin / 2;

        path.moveTo(points[0].x, points[0].y + nodeRadius);
        path.lineTo(
          points[0].x,
          points[0].y + sameLayerOffset - options.arcLength
        );
        path.arcTo(
          points[0].x,
          points[0].y + sameLayerOffset,
          points[0].x + options.arcLength,
          points[0].y + sameLayerOffset,
          options.arcLength
        );
        path.lineTo(
          points[1].x - options.arcLength,
          points[1].y + sameLayerOffset
        );
        path.arcTo(
          points[1].x,
          points[1].y + sameLayerOffset,
          points[1].x,
          points[1].y + sameLayerOffset - options.arcLength,
          options.arcLength
        );
        path.lineTo(points[1].x, points[1].y + nodeRadius);
      }
    } else {
      // Connections across layers.
      let startPoint = points[0];
      path.moveTo(startPoint.x, startPoint.y + nodeRadius);
      for (let i = 1; i < points.length; i++) {
        let point = points[i];

        if (point.x === startPoint.x) {
          path.lineTo(point.x, point.y - nodeRadius);
        } else {
          let rounded = i > 1 || !edge.straightCross;

          let directionX = point.x > startPoint.x ? 1 : -1;
          const midY =
            startPoint.y + options.midLayerOffset + options.edgeMargin / 2;

          if (rounded) {
            path.lineTo(startPoint.x, midY - options.arcLength);
            path.arcTo(
              startPoint.x,
              midY,
              startPoint.x + directionX * options.arcLength,
              midY,
              options.arcLength
            );
          } else {
            path.lineTo(startPoint.x, midY);
          }

          path.lineTo(point.x - directionX * options.arcLength, midY);

          path.arcTo(
            point.x,
            midY,
            point.x,
            point.y - nodeRadius,
            options.arcLength
          );
          path.lineTo(point.x, point.y - nodeRadius);
        }

        startPoint = point;
      }
    }
    return path.toString();
  };

function createEdgePaths(selection: GGroup, g: PshGraph, options: TreeOptions) {
  const sortedEdges = g
    .edges()
    .concat()
    .sort(e => {
      return g.edge(e).active ? 1 : -1;
    });

  let svgNodes = selection
    .selectAll("path.edgePath")
    .data(sortedEdges, function (e: any) {
      return edgeToId(e);
    })
    .classed("update", true)
    .raise();

  svgNodes.enter().append("path").attr("class", "edgePath");

  svgNodes.exit().remove();

  selection.selectAll("path.edgePath").each(function (e: any) {
    let edgePath = d3.select(this);
    let edge = g.edge(e);

    const classList = ["edgePath", edge.class];
    if (edge.active) {
      classList.push("active");
    }
    edge.connectedNodes?.forEach((n: string) => {
      classList.push(n + "-path");
    });

    const classes = classList.join(" ");
    edgePath.attr("class", classes);
    edgePath.attr("d", generatePath(g, options));
  });

  return svgNodes;
}

export default createEdgePaths;
