import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { css, getCSSVarString, SECTION, INPUT } from "Libs/themes";
import CustomScroll from "react-custom-scroll";

import Error from "../Error";
import Label from "../fields/Label";
import Checkbox from "ds/Checkbox";
import ActionDropdown from "../ActionDropdown";

const DropdownLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  .info-button {
    width: 100%;
    button {
      .text {
        line-height: 30px;
        font-size: 15px;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
      }
      .chevron {
        min-width: 24px;
      }
    }
  }
  .required-message {
    display: inline-block;
    color:var(--section-red-color,var(--section-red,var(--red)));
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    label {
      opacity: 0.5;
    }
  }
  &.field-error {
    label,
    .required-message {
      color:var(--section-red-color,var(--section-red,var(--red)));
    }
    .required-notice {
      background-color:var(--section-red-background-color,var(--section-red,var(--red)));
    }
  }
  &.inline {
    .input-wrapper {
      flex-direction: row;
    }
  }
  &.dropdown {
    background-color: transparent;
  }
  .tooltip {
    width: ${props => props.dropdownWidth || "100%"};
    content: ${props => props.dropdownWidth};
    > section {
      padding: 0;
    }
    .tooltip-content > .rcs-custom-scroll {
      margin: 0;
      .rcs-custom-scrollbar {
        right: 4px;
      }
    }
    .scroll-area,
    .no-scroll {
      padding: 8px;
    }
    .checkbox-field {
      margin-bottom: 0;
      padding: 8px;
      box-sizing: border-box;
      label {
        margin: 0;
        line-height: 18px;
        &:before {
          top: 1px;
        }
        &:after {
          top: 5px;
        }
      }
      &:hover {
        background-color:var(--input-skye-light-background-color,var(--input-skye-light,var(--skye-light)));
        border-radius: 4px;
      }
      &:focus-within {
        border-color:var(--input-skye-dark-border-color,var(--input-skye-dark,var(--skye-dark)));
        box-shadow: 0 0 2px
          var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark)));
      }
      &.checked {
        label {
          color:var(--input-skye-color,var(--input-skye,var(--skye)));
          &:before {
            border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
          }
          &:after {
            background-color:var(--input-skye-background-color,var(--input-skye,var(--skye)));
          }
        }
      }
    }
  }
`;

const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  width: auto;
  position: relative;
  &.stacked {
    flex-direction: column;
    align-items: flex-start;
    .Select,
    .Select-control,
    .Select-menu-outer {
      width: 100% !important;
    }
    .Select {
      background: #f7f7f7;
    }
  }
  .Select-input:focus {
    background: transparent;
  }
`;

class DropdownMultiPick extends React.Component {
  constructor(props) {
    super(props);
    this.optionIsActive = this.optionIsActive.bind(this);
    this.optionIsAll = this.optionIsAll.bind(this);
  }

  optionIsActive(option) {
    return this.props.value?.some(item => item.value === option.value);
  }

  optionIsAll() {
    return this.props.value?.some(item => item.value === "all_type");
  }

  render() {
    let activeLabels = [];
    this.props.value &&
      this.props.value.forEach(item => {
        activeLabels = activeLabels.concat(item && item.label);
      });
    const actionDropdownHasArrow =
      this.props.withArrow !== "undefined" ? this.props.withArrow : true;
    const actionDropdownLabel =
      this.props.title ||
      (activeLabels && activeLabels.join(", ")) ||
      (this.props.defaultValue && this.props.defaultValue.label) ||
      this.props.defaultText;

    return (
      <DropdownLayout
        className={`dropdown${
          this.props.className ? " " + this.props.className : ""
        }${this.props.disabled ? " is-disabled" : ""}`}
        dropdownWidth={this.props.dropdownWidth}
      >
        <InputLayout
          id={this.props.label && this.props.label.replace(" ", "-")}
          className="input-wrapper"
        >
          {this.props.label && (
            <Label
              htmlFor={`${
                this.props.label && this.props.label.replace(" ", "-")
              }-options`}
            >
              {this.props.label}
            </Label>
          )}
          <ActionDropdown
            id={
              this.props.label?.replace(" ", "-") + "-toggle" ||
              this.props.id ||
              "dropdown-multi-pick"
            }
            label={actionDropdownLabel}
            ariaLabel={actionDropdownLabel}
            className="select-multi-pick"
            withArrow={actionDropdownHasArrow}
            icon={this.props.icon}
            iconAfter={true}
            withBorder={this.props.withBorder}
            closeOnContentClick={false}
          >
            {this.props.options.length > 5 ? (
              <CustomScroll heightRelativeToParent={this.props.maxHeight}>
                <div className="scroll-area">
                  {this.props.options.map(option => (
                    <Checkbox
                      label={option.label}
                      key={option.value}
                      forId={option.value}
                      checked={
                        this.optionIsAll() || this.optionIsActive(option)
                      }
                      onCheckedChanged={() => {
                        if (option.value !== "all_plans") {
                          this.props.onChange({
                            value: option.value,
                            label: option.label
                          });
                        } else {
                          this.props.onChange(this.props.defaultValue);
                        }

                        document.activeElement.blur();
                      }}
                    />
                  ))}
                </div>
              </CustomScroll>
            ) : (
              <div className="no-scroll">
                {this.props.options.map(option => (
                  <Checkbox
                    label={option.label}
                    key={option.value}
                    forId={option.value}
                    checked={this.optionIsAll() || this.optionIsActive(option)}
                    onCheckedChanged={() => {
                      if (option.value !== "all_plans") {
                        this.props.onChange({
                          value: option.value,
                          label: option.label
                        });
                      } else {
                        this.props.onChange(this.props.defaultValue);
                      }

                      document.activeElement.blur();
                    }}
                  />
                ))}
              </div>
            )}
          </ActionDropdown>
        </InputLayout>
        {this.props.error && <Error>{this.props.error}</Error>}
      </DropdownLayout>
    );
  }
}

DropdownMultiPick.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  error: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.object,
  defaultText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
  options: PropTypes.array,
  icon: PropTypes.component,
  iconAfter: PropTypes.bool,
  withArrow: PropTypes.bool,
  id: PropTypes.string,
  maxHeight: PropTypes.string,
  withBorder: PropTypes.bool,
  title: PropTypes.string,
  dropdownWidth: PropTypes.string
};

export default DropdownMultiPick;
