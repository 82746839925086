import React, { useRef, useState, useMemo, useCallback } from "react";

import InstanceDropdown from "../IntanceDropdown";

import * as S from "./styles";

import { ZoomTransform, select } from "d3";
import getIcon from "Components/icons/technologies";
import ServiceTree, {
  Node,
  Tree,
  GGroup,
  ServiceTreeEventPayload
} from "./ServiceTree";
import Tooltip from "../Tooltip";
import DetailDialog from "../DetailDialog";
import { getCSSVarString, ICON } from "Libs/themes";

type Metadata = {
  node?: ServiceTreeEventPayload;
  visible: boolean;
  position?: string;
};

export interface nodeRendererOptions {
  interfaceId: string;
}

// Need to return {width, height} of the node
function getNodeDimentions() {
  const node = select(".label-container")?.node();
  return (node as SVGGraphicsElement)?.getBoundingClientRect();
}

const nodeRenderer = (options: nodeRendererOptions) =>
  function (rootNode: GGroup, data: Node) {
    const { width, height, icon, iconColor } = data;
    const iconSize = 20;

    // Special trick to avoid the interpolation at build time
    // that is not working in this case
    const getCSSVarStringNoInterpolation = getCSSVarString;

    if (
      options?.interfaceId &&
      data.metadata?.topology?.instances &&
      data.metadata?.topology?.instances.indexOf(options?.interfaceId) === -1
    ) {
      // If the current node is not in the current instance, we show a special empty node
      return rootNode
        .insert("rect", ":first-child")
        .classed("label-container", true)
        .attr("transform", "rotate(-45)")
        .attr("rx", 8)
        .attr("ry", 8)
        .attr("x", -width / 2)
        .attr("y", -height / 2)
        .attr("width", width)
        .attr("height", height)
        .style("fill", getCSSVarStringNoInterpolation(ICON, "grey", "fill"))
        .style("filter", "url(#shadow)");
    }

    const rectNode = rootNode
      .insert("rect", ":first-child")
      .classed("label-container", true)
      .attr("transform", "rotate(-45)")
      .attr("rx", 8)
      .attr("ry", 8)
      .attr("x", -width / 2)
      .attr("y", -height / 2)
      .attr("width", width)
      .attr("height", height)
      .style("filter", "url(#shadow)");

    if (
      (!options?.interfaceId &&
        data.metadata?.topology?.instances?.length > 1) ||
      (options?.interfaceId &&
        data.metadata?.topology?.instances &&
        data.metadata?.topology?.instances.indexOf(options?.interfaceId) === -1)
    ) {
      rootNode
        .insert("rect", ":first-child")
        .classed("label-container", false)
        .attr("transform", "rotate(-45)")
        .attr("rx", 8)
        .attr("ry", 8)
        .attr("x", -width / 2 + 5)
        .attr("y", -height / 2 + 5)
        .attr("width", width)
        .attr("height", height)
        .style("filter", "url(#shadow)");

      rootNode
        .insert("rect", ":first-child")
        .classed("label-container", false)
        .attr("transform", "rotate(-45)")
        .attr("rx", 8)
        .attr("ry", 8)
        .attr("x", -width / 2 + 10)
        .attr("y", -height / 2 + 10)
        .attr("width", width)
        .attr("height", height)
        .style("filter", "url(#shadow)");
    }
    rootNode
      .insert("g")
      .classed("icon", true)
      .html(
        `<image x="-${iconSize / 2}" y="-${
          iconSize / 2
        }" width="${iconSize}" height="${iconSize}" xlink:href="data:image/svg+xml;base64,${window.btoa(
          getIcon(
            icon || "placeholder",
            -(iconSize / 2),
            -(iconSize / 2),
            iconSize,
            iconSize,
            iconColor
          )
        )}"></image>`
      );

    // Add the badge for the workers
    if (data.metadata?.worker) {
      const worker = rootNode
        .insert("g")
        .classed("badge", true)
        .style("transform", "translate(6px, -23px)");

      worker
        .append("circle")
        .attr("cx", 8)
        .attr("cy", 8)
        .attr("r", 8)
        .style("fill", "#C9D0E4");

      worker
        .append("path")
        .attr(
          "d",
          "M12.2876 5.30078H10.875L10.0264 9.50635H9.96729L8.8877 5.30078H7.55566L6.47607 9.50635H6.42236L5.57373 5.30078H4.14502L5.60059 10.9995H7.09912L8.19482 6.94434H8.23779L9.3335 10.9995H10.8267L12.2876 5.30078Z"
        )
        .style("fill", "#1A192B");
    }

    return rectNode;
  };

export interface ServiceGraphProps {
  goToService: (metadata: Metadata) => void;
  hasInfoTooltip: boolean;
  hasInstanceSelector: boolean;
  onClick: (metadata: ServiceTreeEventPayload) => void;
  onLoadEnd: () => void;
  minHeight?: number;
  strokeColor?: string;
  data: Tree;
  width: number;
  instances: Set<string>;
}

function ServiceGraph({
  goToService,
  hasInfoTooltip,
  hasInstanceSelector,
  onClick,
  strokeColor,
  data,
  width,
  onLoadEnd,
  instances
}: ServiceGraphProps) {
  const [tooltipMetadata, setTooltipMetadata] = useState<Metadata>({
    visible: false
  });
  const [dialogMetadata, setDialogMetadata] = useState<Metadata>({
    visible: false
  });
  const [lastNode, setLastNode] = useState<HTMLElement | null>();
  const [currentInstance, setCurrentInstance] = useState<string>("");
  const innerRef = useRef(null);

  const leftOffset = 0;
  const graphHeight = 220;

  const onNodeOver =
    (scale: number, transform: ZoomTransform) =>
    (e: CustomEvent<ServiceTreeEventPayload>) => {
      // If the dialog is visible, we don't want to show the tooltip
      if (dialogMetadata?.visible) {
        return false;
      }
      const leftGap = 16; // Space between the start of the div and the arrow
      const tX = e.detail.x * scale + transform.x - leftGap - leftOffset;

      // The tooltip div start on the bottom of the service tree div
      const tY =
        -graphHeight + // We subtract the service tree div height
        transform.y + // Add the translation
        e.detail.y * scale + // to the current position (* the calculated scale)
        e.detail.size * scale - // + all the node size (* the calculated scale)
        2; // To be closer to the node

      setTooltipMetadata({
        node: e.detail,
        position: `translate(${tX}px, ${tY}px)`,
        visible: true
      });
    };

  const onNodeOut = () => () => {
    setTooltipMetadata({
      ...tooltipMetadata,
      visible: false
    });
  };

  const insertDetailDialog = (
    e: CustomEvent<ServiceTreeEventPayload>,
    scale: number,
    transform: ZoomTransform
  ) => {
    const leftGap = 15; // Space between the start of the div and the arrow 5% * ~277

    //@ts-expect-error
    Array.from<HTMLElement>(e.target.parentElement.children).forEach(
      (node: HTMLElement) => node.setAttribute("aria-expanded", "false")
    );
    //@ts-expect-error
    e.target.setAttribute("aria-expanded", "true");

    const tX = e.detail.x * scale + transform.x - leftGap - leftOffset;
    const tY =
      -graphHeight + // We subtract the service tree div height
      transform.y + // Add the translation
      e.detail.y * scale + // to the current position (* the calculated scale)
      e.detail.size * scale - // + all the node size (* the calculated scale)
      2; // To be closer to the node

    setDialogMetadata({
      node: e.detail,
      position: `translate(${tX}px, ${tY}px)`,
      visible: true
    });

    // Check if we need to resize window to display correctly the dialog
    // const rect = innerRef.current?.getBoundingClientRect();
    // if (rect.bottom + 150 > window.innerHeight) sendFooterEvent(150);
  };

  const closeDetailDialog = () => {
    // sendFooterEvent(0);

    Array.from(document.querySelectorAll("svg .nodes .node")).forEach(node =>
      node.setAttribute("aria-expanded", "")
    );

    lastNode?.focus();
    setDialogMetadata({
      visible: false
    });
  };

  const onTab = (e: KeyboardEvent) => {
    if (e.which !== 9) return;

    const isTab = e.which === 9 && !e.shiftKey;
    const isSTab = e.which === 9 && e.shiftKey;
    const isNode = document.activeElement?.nodeName === "g";

    if (dialogMetadata.visible && isTab && isNode) {
      setLastNode(document.activeElement as HTMLElement);
      e.preventDefault();
      (
        document.querySelector("#service-tree+div .close") as HTMLElement
      )?.focus();
    }

    if (dialogMetadata.visible && !isNode) {
      const focusableNodes = document.querySelectorAll(
        "#service-tree+div [tabindex]"
      );

      if (
        (isSTab && document.activeElement?.isSameNode(focusableNodes[0])) ||
        (isTab &&
          document.activeElement?.isSameNode(
            focusableNodes[focusableNodes.length - 1]
          ))
      ) {
        e.preventDefault();
        closeDetailDialog();
      }
    }
  };

  const onEsc = (e: KeyboardEvent) => {
    if (e.which !== 27) {
      return;
    }
    setTooltipMetadata({ visible: false });
  };

  const onKeyDown = () => (e: KeyboardEvent) => {
    onEsc(e);
    onTab(e);
  };

  const onNodeClick =
    (scale: number, transform: ZoomTransform) =>
    (e: CustomEvent<ServiceTreeEventPayload>) => {
      if (hasInfoTooltip) {
        insertDetailDialog(e, scale, transform);
      }
      setTooltipMetadata({
        visible: false
      });

      onClick(e.detail);
    };

  const onChange = (value: string) => {
    setCurrentInstance(value);
  };

  const instancesArray = useMemo(() => {
    if (!instances) {
      return [];
    }
    return [...instances];
  }, [instances]);

  const nodeRendererCallback = useCallback(
    nodeRenderer({
      interfaceId: currentInstance
    }),
    [currentInstance]
  );

  return (
    <S.TreeLayout id="treeSvg" strokeColor={strokeColor}>
      <ServiceTree
        data={data}
        id="service-tree"
        maxHeight={graphHeight}
        treePositionY={30}
        nodeRenderer={nodeRendererCallback}
        getNodeDimentions={getNodeDimentions}
        width={width}
        innerRef={innerRef}
        onNodeOver={onNodeOver}
        onNodeOut={onNodeOut}
        onKeyDown={onKeyDown}
        onNodeClick={onNodeClick}
        gridStepX={60}
        gridStepY={80}
        arcLength={10}
        midLayerOffset={40}
        edgeMargin={10}
        onLoadEnd={onLoadEnd}
      />
      {hasInstanceSelector && !!instancesArray.length && (
        <div style={{ position: "absolute", right: 0, marginRight: 22 }}>
          <InstanceDropdown
            instances={instancesArray}
            onChange={onChange}
            currentInstance={currentInstance}
          />
        </div>
      )}
      {dialogMetadata.visible && (
        <DetailDialog
          metadata={dialogMetadata?.node?.metadata}
          transform={dialogMetadata.position}
          currentInstance={currentInstance}
          onClick={goToService}
          onClose={() => closeDetailDialog()}
        />
      )}
      {tooltipMetadata.visible && (
        <Tooltip
          metadata={tooltipMetadata?.node?.metadata}
          transform={tooltipMetadata?.position}
        />
      )}
    </S.TreeLayout>
  );
}

export default ServiceGraph;
