import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { css, SECTION } from "Libs/themes";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

const InvitationRevokeModal = ({
  isOpen,
  closeModal,
  email,
  revokeInvitation
}) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-small"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "invitation.revokeModalTitle" })}
      id={"invitation-revoke-modal"}
    >
      <ModalBody className="modal-body">
        {intl.formatMessage(
          { id: "invitation.revokeModalBody" },
          {
            email
          }
        )}
      </ModalBody>
      <ButtonWrapper spacing="modal" justifyContent="end">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button onClick={revokeInvitation}>
          {intl.formatMessage({ id: "invitation.revoke" })}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

InvitationRevokeModal.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  closeModal: PropTypes.func,
  revokeInvitation: PropTypes.func
};

export default InvitationRevokeModal;
