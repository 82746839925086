import styled from "styled-components";

import { css, SECTION, ICON } from "Libs/themes";

export const Layout = styled.div`
  .chevron-link {
    svg {
      path {
        ${css(ICON, "granite", "fill")};
      }
    }
  }

  nav {
    min-width: 220px;
  }

  .radio-field label {
    font-size: 14px;
  }
`;

export const CurrentInstance = styled.span`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
  ${css(SECTION, "slate", "color")}
`;

export const MenuLayout = styled.div`
  padding: 20px 0px 0px 20px;
`;
