import styled from "styled-components";

export const Wrapper = styled.div`
  width: 316px;
  .dropdown {
    display: block;
    margin-bottom: 0;
    .input-wrapper {
      flex-direction: column;
      margin-bottom: 16px !important;
      align-items: flex-start;
    }

    .Select-menu-outer {
      .Select-option {
        padding-left: 2em;
      }
      .Select-option.is-disabled {
        cursor: default;
        padding-left: 1em;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .description {
    display: flex;
    align-items: center;
    .info-dialog {
      margin: 0 12px 0 0;
    }
  }
`;
