import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
`;

export const Icon = styled.div`
  flex: 0;
  width: 16px;
  margin-right: 8px;
  padding-top: 4px;
`;

export const Default = styled.div`
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const Details = styled.div`
  flex: 1;
  font-weight: normal;
`;

export const Label = styled.div`
  margin: 0 0 8px 0;
  font-size: 13px;
  line-height: 16px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  ${semiBoldAlias};
`;

export const Logo = styled.div`
  position: absolute;
  right: 0;
  img,
  svg {
    width: 40px;
    height: 40px;
  }
`;
