import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import { getRegions, regionsByZoneSelector } from "Reducers/project/region";
import {
  getOrganizationRegions,
  organizationRegionsByZoneSelector
} from "Reducers/organization/region";

import Dropdown from "Components/Dropdown";
import EnvironmentImpact from "../EnvironmentImpact";

import * as S from "./RegionPicker.styles";

const RegionPicker = ({
  className = "",
  errors,
  organizationId,
  onChange,
  region
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (process.env.ENABLE_ORGANIZATION && organizationId) {
      dispatch(getOrganizationRegions({ organizationId }));
    } else {
      dispatch(getRegions());
    }
  }, [organizationId]);

  const options = useSelector(({ organizationSetup, setup }) => {
    if (process.env.ENABLE_ORGANIZATION && organizationId) {
      return organizationSetup.getIn(
        [organizationId, "options", "data", "regions"],
        Map()
      );
    }
    return setup.getIn(["options", "data", "regions"], Map());
  })?.toJS();

  const regions = useSelector(state => {
    if (process.env.ENABLE_ORGANIZATION && organizationId) {
      return organizationRegionsByZoneSelector(state, { organizationId });
    }
    return regionsByZoneSelector(state);
  })?.toJS();

  const handleChange = region => {
    onChange(region);
  };

  const getOptions = () => {
    const optionsList =
      typeof options.isArray === "function" && options.isArray()
        ? options
        : options.only;
    return optionsList || [];
  };

  const listOptions = useMemo(() => {
    const restrictedRegions = getOptions();

    let list = [];
    Object.keys(regions).forEach(zone => {
      const optionsForZone = (
        restrictedRegions.length === 0
          ? regions[zone]
          : regions[zone].filter(region => {
              // The ID of the region for British Council was not done the same as others.
              // Moving forward, all regions have the ID of their fully qualified URL. For now
              // it is easier to hard code this one exception than change the ID for a whole region.
              return (
                (region.id === "bc" &&
                  restrictedRegions.indexOf("bc.platform.sh") !== -1) ||
                restrictedRegions.indexOf(region.id) !== -1
              );
            })
      ).map(elt => {
        const label = elt.data?.provider?.name
          ? `${elt.label} - ${elt.data?.provider?.name}`
          : elt.label;
        return { ...elt, label };
      });

      list.push({
        label: zone === "" ? "Other" : zone,
        value: zone === "" ? "Other" : zone,
        options: optionsForZone
      });
    });

    return list;
  }, [options, regions]);

  if (!options) return null;

  return (
    <S.Wrapper className={className}>
      <Dropdown
        label="Region"
        options={listOptions}
        defaultValue={region}
        searchable
        onChange={handleChange}
        clearable={false}
        error={errors}
        fieldType={true}
        required={true}
        defaultText={intl.formatMessage({ id: "region_picker.default_text" })}
      />

      <EnvironmentImpact region={region} />
    </S.Wrapper>
  );
};

RegionPicker.propTypes = {
  className: PropTypes.object,
  errors: PropTypes.object,
  organizationId: PropTypes.string,
  onChange: PropTypes.func,
  region: PropTypes.object
};

export default RegionPicker;
