import React from "react";

const Gcp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8647 8.57279H14.3619L15.779 7.19869L15.8486 6.61529C15.0368 5.9205 14.0557 5.43786 12.998 5.21295C11.9404 4.98804 10.841 5.02829 9.80385 5.3299C8.76667 5.63151 7.82591 6.18452 7.07044 6.93669C6.31497 7.68887 5.76972 8.61537 5.48621 9.62869C5.64408 9.56594 5.81896 9.55577 5.98344 9.59976L8.8177 9.14654C8.8177 9.14654 8.9619 8.91511 9.03648 8.92958C9.64377 8.28286 10.483 7.88569 11.3825 7.81936C12.2819 7.75303 13.1734 8.02257 13.8746 8.57279H13.8647Z"
        fill="#EA4335"
      />
      <path
        d="M17.7978 9.62869C17.472 8.46558 16.8033 7.41997 15.8735 6.62012L13.8845 8.54869C14.299 8.87711 14.6312 9.29251 14.856 9.76356C15.0809 10.2346 15.1924 10.749 15.1823 11.268V11.6103C15.4148 11.6103 15.645 11.6547 15.8597 11.741C16.0745 11.8272 16.2696 11.9536 16.434 12.113C16.5984 12.2724 16.7288 12.4616 16.8177 12.6699C16.9067 12.8781 16.9525 13.1013 16.9525 13.3267C16.9525 13.5521 16.9067 13.7753 16.8177 13.9836C16.7288 14.1918 16.5984 14.381 16.434 14.5404C16.2696 14.6998 16.0745 14.8262 15.8597 14.9125C15.645 14.9988 15.4148 15.0432 15.1823 15.0432H11.642L11.2889 15.3903V17.449L11.642 17.7914H15.1823C16.1709 17.7988 17.1358 17.4975 17.9341 16.9321C18.7325 16.3666 19.3219 15.567 19.6152 14.6515C19.9086 13.736 19.8902 12.7533 19.5629 11.8488C19.2356 10.9442 18.6167 10.1658 17.7978 9.62869Z"
        fill="#4285F4"
      />
      <path
        d="M8.0967 17.7719H11.637V15.0237H8.0967C7.84446 15.0236 7.59519 14.971 7.36576 14.8694L6.86852 15.0188L5.44145 16.393L5.31714 16.8751C6.11741 17.461 7.09391 17.7761 8.0967 17.7719Z"
        fill="#34A853"
      />
      <path
        d="M8.09669 8.85721C7.13743 8.86276 6.20391 9.15869 5.42652 9.70366C4.64913 10.2486 4.06669 11.0154 3.76055 11.8969C3.45441 12.7784 3.43985 13.7307 3.71892 14.6206C3.99799 15.5105 4.55674 16.2937 5.31713 16.8608L7.37072 14.8695C7.10975 14.7552 6.88129 14.5812 6.70492 14.3624C6.52856 14.1437 6.40956 13.8867 6.35815 13.6135C6.30673 13.3404 6.32443 13.0592 6.40972 12.7941C6.49502 12.529 6.64536 12.288 6.84785 12.0916C7.05034 11.8953 7.29894 11.7495 7.57231 11.6668C7.84568 11.5841 8.13566 11.5669 8.41737 11.6168C8.69909 11.6667 8.96412 11.782 9.18973 11.953C9.41535 12.1241 9.59481 12.3456 9.71271 12.5986L11.7663 10.6074C11.3353 10.061 10.7797 9.6187 10.1431 9.31508C9.50653 9.01146 8.80613 8.85475 8.09669 8.85721Z"
        fill="#FBBC05"
      />
    </svg>
  );
};

export default Gcp;
