import React, { useEffect, useState } from "react";
import { Graph, Edge as GraphlibEdge } from "graphlib";
import { Selection, select, selectAll, zoomIdentity, ZoomTransform } from "d3";

import createEdgePaths from "./edges";

export class PshGraph extends Graph {
  hasAppEdges: boolean;
  hasServiceOverlap: boolean;

  width: number;
  height: number;

  constructor() {
    super();

    this.hasServiceOverlap = false;
    this.hasAppEdges = false;
    this.width = 0;
    this.height = 0;
  }
}

export type Node = {
  id: string;
  icon: string;
  iconColor?: string;
  line: number;
  column: number;
  width: number;
  height: number;
  children: Array<string>;
  metadata?: Record<string, any>;
};

export type Tree = Array<Node>;

export type TreeOptions = {
  gridStepX: number;
  gridStepY: number;

  sizeX?: number;
  sizeY?: number;

  maxHeight: number;
  arcLength: number;
  midLayerOffset: number;
  edgeMargin: number;

  treePositionY: number;

  nodeRenderer: (root: GGroup, node: Node) => GGroup;
  getNodeDimentions: () => { width: number; height: number };
};

export type SVGRect = Selection<any, any, any, any>;
export type GGroup = Selection<any, any, any, any>;

export interface Edge extends GraphlibEdge {
  connectedNodes: Array<string>;
}

export type ServiceTreeEventPayload = {
  x: number;
  y: number;
  size: number;
  metadata: Record<string, any>;
  class: string;
  icon: string;
};

// maximum treeWidth for large/medium padding
const LARGE_PADDING_THRESHOLD = 4;
const MED_PADDING_THRESHOLD = 7;
const MIN_NODE_WIDTH = 24;
const MAX_NODE_WIDTH = 57;

function createOrSelectGroup(root: GGroup, name: string): GGroup {
  let selection: GGroup = root.select("g." + name);
  if (selection.empty()) {
    selection = root.append("g").attr("class", name);
  }
  return selection;
}

function generateAST(data: Tree) {
  const g = new PshGraph();
  g.setGraph({});

  let maxColumn: number = 0;
  let maxLine: number = 0;

  data.forEach(d => {
    g.setNode(d.id, {
      id: d.id,
      icon: d.icon,
      line: d.line,
      column: d.column,
      width: d.width,
      height: d.height,
      iconColor: d.iconColor,
      children: d.children,
      metadata: d.metadata
    });

    if (maxColumn < d.column) {
      maxColumn = d.column;
    }

    if (maxLine < d.line) {
      maxLine = d.line;
    }

    d.children.forEach(c => {
      g.setEdge(d.id, c, {});
    });
  });

  return { graph: g, sizeX: maxColumn, sizeY: maxLine };
}

// Assign node positions
function layout(g: PshGraph, options: TreeOptions) {
  const gridStepY = options.gridStepY;
  let gridStepX = options.gridStepX;

  // reduce padding for wide graphs
  if (options.sizeX && options.sizeX > LARGE_PADDING_THRESHOLD) {
    gridStepX = 80;
  }
  if (options.sizeX && options.sizeX > MED_PADDING_THRESHOLD) {
    gridStepX = 55;
  }

  g.nodes().forEach(n => {
    const node = g.node(n);

    node.x = node.column * gridStepX;
    node.y = node.line * gridStepY;
  });
}

// Draw nodes and attach events
function draw(id: string, g: PshGraph, options: TreeOptions) {
  const svgGraph = select(`#${id}`);
  const svg = svgGraph.select("svg");

  const selection = createOrSelectGroup(
    createOrSelectGroup(svg.select("g"), "output"),
    "nodes"
  );
  let svgNodes = selection
    .selectAll("g.node")
    .data(g.nodes(), v => {
      return v as string;
    })
    .classed("update", true);

  svgNodes
    .enter()
    .append("g")
    .attr("class", "node")
    .attr("tabindex", 0)
    .attr("aria-labelledby", "node-tooltip")
    .attr("aria-haspopup", true)
    .attr("aria-expanded", false)
    .style("opacity", 0);

  svgNodes = selection.selectAll("g.node");

  svgNodes.each(function (v: string) {
    const node = g.node(v),
      thisGroup = select(this as SVGGraphicsElement);
    if (!node) {
      // The node is not in the graph anymore
      // We remove it from the DOM
      thisGroup.remove();
      return;
    }
    thisGroup
      .attr("class", node["class"])
      .attr(
        "class",
        `${thisGroup.classed("update") ? "update " : ""}node ${
          thisGroup.attr("class") || ""
        }`
      );

    node.elem = this;

    const { width } = node;

    if (node.id) {
      thisGroup.attr("id", node.id);
    }
    if (node.labelId) {
      thisGroup.attr("id", node.labelId);
    }

    // Add the rectangle
    // Remove the rect and icons in case of re-render
    thisGroup.selectAll("*").remove();

    // Add the rectangle to the SVG
    const rectNode = options.nodeRenderer(thisGroup, node);

    // Create and attach events
    const activate = () => {
      rectNode.style("filter", "url(#shadow-hover)");
      selectAll("." + v + "-path")
        .classed("highlight", true)
        .raise();
    };

    const deactivate = () => {
      rectNode.style("filter", "url(#shadow)");
      selectAll(".edgePath").classed("highlight", false);
    };

    const customEventFactory = (name: string) =>
      new CustomEvent(name, {
        detail: {
          x: node.x,
          y: node.y,
          size: width,
          metadata: node.metadata,
          class: node.class,
          icon: node.icon
        }
      });

    const onMouseout = () => {
      deactivate();
      (this as Element)?.dispatchEvent(customEventFactory("treeSvgOut"));
    };

    const onMouseover = () => {
      activate();
      (this as Element)?.dispatchEvent(customEventFactory("treeSvgOver"));
    };

    const onClick = () => {
      (this as Element)?.dispatchEvent(customEventFactory("treeSvgClick"));
    };

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.which === 13) {
        return (this as Element)?.dispatchEvent(
          customEventFactory("treeSvgClick")
        );
      }
    };

    thisGroup
      .on("focus", onMouseover)
      .on("blur", onMouseout)
      .on("mouseover", onMouseover)
      .on("mouseout", onMouseout)
      .on("click", onClick)
      .on("keydown", onKeyDown, false);
  });

  // We want to re-select the nodes because we could have removed some nodes
  svgNodes = selection.selectAll("g.node");

  return svgNodes;
}

// Position svg nodes on the DOM
function positionNodes(selection: GGroup, g: PshGraph, id: string) {
  let created = selection.filter(function () {
    return !select(this).classed("update");
  });
  function translate(v: string) {
    let node = g.node(v);
    return "translate(" + node.x + "," + node.y + ")";
  }

  created.attr("transform", translate);

  selection.style("opacity", 1).attr("transform", translate);

  const svgGraph = select(`#${id}`);
  const svg = svgGraph.select("svg");

  let outputGroup = createOrSelectGroup(svg, "output");
  let shapeBBox = (outputGroup.node() as SVGGraphicsElement)?.getBBox();
  g.width = shapeBBox?.width;
  g.height = shapeBBox?.height;
}

// Assign values to edges before we render them
function preProcessEdges(g: PshGraph) {
  g.nodes().forEach((n: string) => {
    let node = g.node(n);
    let edges = g.outEdges(n);

    let hasLeftEdge = false,
      hasRightEdge = false;

    edges?.forEach(e => {
      let target = g.node(e.w);
      let edge = g.edge(e);

      if (target.line === node.line) {
        edge.class = `same ${e.v}-path`; // PF-7723: quick and dirty solution to highlight workers' edge path
        return;
      }

      if (target.x < node.x) {
        edge.class = "left";
        hasLeftEdge = true;
      } else if (target.x > node.x) {
        edge.class = "right";
        hasRightEdge = true;
      } else {
        edge.class = "middle";
      }
    });

    let shouldStraightCross = hasLeftEdge && hasRightEdge;
    edges?.forEach(e => {
      g.edge(e).straightCross = shouldStraightCross;
    });
  });
}

// Scale the all graph to fit the container
function scale(id: string, g: PshGraph, options: TreeOptions) {
  let graph = select(`#${id}`),
    svg = graph.select("svg"),
    inner = svg.select("g");

  // Remove any transform because we are measuring the node
  // in the actual DOM to do the calculations
  inner.style("transform", "");

  const node = inner.select(".node").node() as SVGGraphicsElement;

  // Center the graph
  let maxX = (graph.node() as HTMLElement)?.offsetWidth,
    maxY = (graph.node() as HTMLElement)?.offsetHeight;

  let padding = 5; // We need a minimum of padding to avoid clipping drop shadows.
  let nodeWidth = options.getNodeDimentions().width;

  let initialScale = Math.min(
    (maxX - 2 * padding) / g.width,
    (options.maxHeight - 2 * padding) / g.height,
    MAX_NODE_WIDTH / nodeWidth
  );

  // Modify scale if needed to maintain minimum node width
  initialScale = Math.max(initialScale, MIN_NODE_WIDTH / nodeWidth);

  // Since SVG translation is calculated without taking into account node padding on
  // edges of tree, calculate and add to final translation for centering
  const nodePaddingX =
    (node?.getBBox()?.width - nodeWidth * initialScale) / 2 || 0;

  let transformD3 = zoomIdentity
    .translate(
      (nodePaddingX + maxX - g.width * initialScale) / 2 + 10,
      options.treePositionY !== undefined
        ? options.treePositionY
        : (maxY - g.height * initialScale) / 2
    )
    .scale(initialScale);

  if (transformD3.x < 0) {
    //@ts-expect-error
    transformD3.x = 0;
  }

  inner.style(
    "transform",
    `translate(${transformD3.x}px, ${transformD3.y}px) scale(${transformD3.k})`
  );

  svg
    .attr("width", maxX)
    .attr("height", maxY)
    .attr("xmlns", "http://www.w3.org/2000/svg");

  return { scale: initialScale, transform: transformD3 };
}

// Assign connected node for all edges
const setHighlightPaths = (
  g: PshGraph,
  v: string,
  edges: Array<Edge>,
  visited: Set<String>
) => {
  let node = g.node(v);
  if (edges.length > 0) {
    edges.forEach(e => {
      if (!e.connectedNodes) {
        e.connectedNodes = [];
      }
      e.connectedNodes.push(v);
    });
  }
  if (node.children.length > 0) {
    node.children.forEach((w: string) => {
      if (!visited.has(w)) {
        let edge = g.edge({ v: v, w: w });
        // make recursive call with only current visited set
        let visitedCopy = new Set(visited);
        visitedCopy.add(v);
        setHighlightPaths(g, w, edges.concat(edge), visitedCopy);
      }
    });
  }
};

export interface ServiceTreeProps extends TreeOptions {
  id: string;
  data: Tree;
  maxHeight: number;
  width: number;
  innerRef: any;
  onLoadEnd: () => void;
  onNodeClick?: (
    scale: number,
    transform: ZoomTransform
  ) => (e: CustomEvent<ServiceTreeEventPayload>) => void;
  onNodeOut?: (
    scale: number,
    transform: ZoomTransform
  ) => (e: CustomEvent<ServiceTreeEventPayload>) => void;
  onNodeOver?: (
    scale: number,
    transform: ZoomTransform
  ) => (e: CustomEvent<ServiceTreeEventPayload>) => void;
  onKeyDown?: (
    scale: number,
    transform: ZoomTransform
  ) => (e: KeyboardEvent) => void;
}

const ServiceTree = ({
  id,
  data,
  maxHeight,
  treePositionY = 10,
  nodeRenderer,
  getNodeDimentions,
  width,
  innerRef,
  onNodeClick,
  onKeyDown,
  onNodeOut,
  onNodeOver,
  onLoadEnd,
  gridStepX = 60,
  gridStepY = 80,
  arcLength = 10,
  midLayerOffset = 40,
  edgeMargin = 10
}: ServiceTreeProps) => {
  const [scaleValue, setScaleValue] = useState(1);
  const [transformValue, setTransformValue] = useState<ZoomTransform>();

  useEffect(() => {
    if (!data || !data.length) return;

    // The user is passing data, and so is doing the order step
    // Generate graph like an AST
    const { graph, sizeX, sizeY } = generateAST(data);

    const options: TreeOptions = {
      sizeX,
      sizeY,
      gridStepX,
      gridStepY,
      arcLength,
      midLayerOffset,
      edgeMargin,
      maxHeight,
      treePositionY,
      nodeRenderer,
      getNodeDimentions
    };

    // Generating layout?
    layout(graph, options);

    // Draw
    let svg = draw(id, graph, options);

    positionNodes(svg, graph, id);

    setHighlightPaths(graph, "router", [], new Set([]));

    preProcessEdges(graph);

    // Get back to the root of the graph to draw the edges
    let d = select(`#${id}`);
    const svgRoot = d.select("svg");
    const outputGroup = createOrSelectGroup(svgRoot, "output");
    const edgePathsGroup = createOrSelectGroup(outputGroup, "edgePaths");
    createEdgePaths(edgePathsGroup, graph, options);

    // Center and scale the graph
    const { scale: s, transform } = scale(id, graph, options);

    setScaleValue(s);
    setTransformValue(transform);
    if (onLoadEnd) onLoadEnd();
  }, [data, id, maxHeight, treePositionY, nodeRenderer]);

  useEffect(() => {
    const svg = innerRef.current;

    if (!scale || transformValue === undefined) {
      return;
    }

    let onNodeClickHandler: (e: CustomEvent<ServiceTreeEventPayload>) => void;
    let onKeyDownHandler: (e: KeyboardEvent) => void;
    let onNodeOverHandler: (e: CustomEvent<ServiceTreeEventPayload>) => void;
    let onNodeOutHandler: (e: CustomEvent<ServiceTreeEventPayload>) => void;

    // Subscribe to events
    if (onNodeClick) {
      onNodeClickHandler = onNodeClick(scaleValue, transformValue);
      svg.addEventListener("treeSvgClick", onNodeClickHandler, true);
    }
    if (onKeyDown) {
      onKeyDownHandler = onKeyDown(scaleValue, transformValue);
      svg.addEventListener("keydown", onKeyDownHandler, false);
    }
    if (onNodeOver) {
      onNodeOverHandler = onNodeOver(scaleValue, transformValue);
      svg.addEventListener("treeSvgOver", onNodeOverHandler, true);
    }
    if (onNodeOut) {
      onNodeOutHandler = onNodeOut(scaleValue, transformValue);
      svg.addEventListener("treeSvgOut", onNodeOutHandler, true);
    }

    return () => {
      // Unsubscribe from events
      if (onNodeClick) {
        svg.removeEventListener("treeSvgClick", onNodeClickHandler, true);
      }
      if (onKeyDown) {
        svg.removeEventListener("keydown", onKeyDownHandler, false);
      }
      if (onNodeOver) {
        svg.removeEventListener("treeSvgOver", onNodeOverHandler, true);
      }
      if (onNodeOut) {
        svg.removeEventListener("treeSvgOut", onNodeOutHandler, true);
      }
    };
  }, [
    innerRef,
    scaleValue,
    transformValue,
    onNodeClick,
    onKeyDown,
    onNodeOver,
    onNodeOut
  ]);

  return (
    <div id={id} style={{ width, height: maxHeight }} ref={innerRef}>
      <svg style={{ overflow: "visible" }}>
        <defs>
          <filter id="shadow" width="200%" height="200%" x="-50%" y="-50%">
            <feDropShadow
              dx="0"
              dy="0.5"
              stdDeviation="2"
              floodColor="rgba(152, 160, 171, 0.4)"
              floodOpacity="1"
            />
          </filter>
          <filter
            id="shadow-hover"
            width="200%"
            height="200%"
            x="-50%"
            y="-50%"
          >
            <feDropShadow
              dx="0"
              dy="3"
              stdDeviation="6"
              floodColor="rgba(75, 97, 128, 0.32)"
              floodOpacity="2"
            />
          </filter>
        </defs>
        <g id="svg-g" />
      </svg>
    </div>
  );
};

export default React.memo(ServiceTree);
