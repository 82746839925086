import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";

import * as S from "./NotificationModal.styles";

const NotificationModal = ({
  message,
  title,
  onConfirm,
  isOpen,
  className
}) => (
  <ModalWrapper
    modalClass="modal-small"
    isOpen={isOpen}
    closeModal={onConfirm}
    title={title}
    className={className}
  >
    <S.ModalBody className="modal-buttons">{message}</S.ModalBody>
    <ButtonWrapper spacing="modal" justifyContent="end">
      <Button onClick={onConfirm}>
        <FormattedMessage id="okay" />
      </Button>
    </ButtonWrapper>
  </ModalWrapper>
);

NotificationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string
};

export default NotificationModal;
