import React from "react";
import { useTheme } from "styled-components";

const Ovh = () => {
  const theme = useTheme();

  const [mainColor, secondaryColor] = ["contrast", "dark"].includes(theme?.name)
    ? ["#fff", "#fff"]
    : ["#264670", "#264670"];

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7527_21740)">
        <path
          d="M17.3511 5.33927L16.0466 7.58822H14.6765L13.0596 10.3718H14.432L13.394 12.1599H16.765C17.6266 11.115 18.1441 9.77977 18.1441 8.32453C18.1441 7.23876 17.8549 6.22073 17.3511 5.33927Z"
          fill={mainColor}
        />
        <path
          d="M10.675 12.1599H10.6783L10.6766 12.1569L14.8652 4.93977H11.3218L8.90611 9.10493L6.7196 5.33586C6.21451 6.21798 5.92456 7.23733 5.92456 8.32453C5.92456 9.77988 6.44195 11.115 7.30372 12.1599H10.675Z"
          fill={mainColor}
        />
        <path
          d="M0.876959 17.0553C0.876959 18.2534 1.42816 18.8249 2.37571 18.8249C3.32338 18.8249 3.87458 18.2534 3.87458 17.0553C3.87458 15.851 3.32338 15.2796 2.37571 15.2796C1.42816 15.2796 0.876959 15.851 0.876959 17.0553ZM0.443359 17.0553C0.443359 15.599 1.17423 14.8986 2.37571 14.8986C3.57731 14.8986 4.30807 15.599 4.30807 17.0553C4.30807 18.4992 3.57731 19.2058 2.37571 19.2058C1.17423 19.2058 0.443359 18.4992 0.443359 17.0553Z"
          fill={mainColor}
        />
        <path
          d="M7.62148 15.0705C7.63999 15.0214 7.70206 14.9354 7.81966 14.9354C7.93737 14.9354 8.03025 15.0275 8.03025 15.1443C8.03025 15.1812 8.01784 15.218 8.01163 15.2365L6.56242 19.0275C6.5315 19.1136 6.44471 19.1689 6.36424 19.1689C6.28366 19.1689 6.19699 19.1136 6.16606 19.0275L4.71674 15.2365C4.71053 15.218 4.69812 15.1812 4.69812 15.1443C4.69812 15.0275 4.79111 14.9354 4.90871 14.9354C5.02642 14.9354 5.08838 15.0214 5.10689 15.0705L6.36424 18.3762L7.62148 15.0705Z"
          fill={mainColor}
        />
        <path
          d="M9.06444 17.1905V18.9601C9.06444 19.0706 8.97155 19.1689 8.85384 19.1689C8.73624 19.1689 8.64325 19.0706 8.64325 18.9601V15.1444C8.64325 15.0338 8.73624 14.9354 8.85384 14.9354C8.97155 14.9354 9.06444 15.0338 9.06444 15.1444V16.8095H11.2197V15.1444C11.2197 15.0338 11.3127 14.9354 11.4303 14.9354C11.548 14.9354 11.6409 15.0338 11.6409 15.1444V18.9601C11.6409 19.0706 11.548 19.1689 11.4303 19.1689C11.3127 19.1689 11.2197 19.0706 11.2197 18.9601V17.1905H9.06444Z"
          fill={mainColor}
        />
        <path
          d="M12.3407 18.8802C12.3407 18.6773 12.4522 18.5852 12.6318 18.5852C12.8114 18.5852 12.9229 18.6773 12.9229 18.8802C12.9229 19.083 12.8114 19.1689 12.6318 19.1689C12.4522 19.1689 12.3407 19.083 12.3407 18.8802"
          fill={secondaryColor}
        />
        <path
          d="M14.6198 16.2442C15.171 16.2442 15.4745 16.533 15.5734 16.6681C15.6045 16.7111 15.6169 16.7419 15.6169 16.7849C15.6169 16.8894 15.5364 16.9631 15.4373 16.9631C15.3753 16.9631 15.332 16.9385 15.2886 16.8894C15.1957 16.7849 15.0286 16.6006 14.6198 16.6006C14.05 16.6006 13.7465 16.9631 13.7465 17.7188C13.7465 18.487 14.05 18.8433 14.6198 18.8433C14.9789 18.8433 15.1957 18.6837 15.3381 18.5791C15.3816 18.5484 15.4125 18.5361 15.4558 18.5361C15.555 18.5361 15.6355 18.6159 15.6355 18.7143C15.6355 18.7696 15.6169 18.8125 15.555 18.8678C15.4187 18.9785 15.1213 19.1997 14.6198 19.1997C13.8209 19.1997 13.3439 18.7265 13.3439 17.7188C13.3439 16.7111 13.8209 16.2442 14.6198 16.2442Z"
          fill={secondaryColor}
        />
        <path
          d="M18.2738 17.7188C18.2738 16.9447 17.9456 16.6006 17.3758 16.6006C16.806 16.6006 16.4778 16.9447 16.4778 17.7188C16.4778 18.5053 16.806 18.8433 17.3758 18.8433C17.9456 18.8433 18.2738 18.5053 18.2738 17.7188ZM18.6765 17.7188C18.6765 18.7265 18.1809 19.1997 17.3758 19.1997C16.5767 19.1997 16.0752 18.7265 16.0752 17.7188C16.0752 16.7111 16.5767 16.2442 17.3758 16.2442C18.1809 16.2442 18.6765 16.7111 18.6765 17.7188Z"
          fill={secondaryColor}
        />
        <path
          d="M23.5567 17.3318V18.9723C23.5567 19.083 23.47 19.1689 23.3585 19.1689C23.247 19.1689 23.1603 19.083 23.1603 18.9723V17.4116C23.1603 16.9263 22.9868 16.5944 22.386 16.5944C21.8535 16.5944 21.6119 16.877 21.6119 17.3318V18.9723C21.6119 19.083 21.5252 19.1689 21.4137 19.1689C21.3022 19.1689 21.2155 19.083 21.2155 18.9723V17.4116C21.2155 16.9263 21.0607 16.5944 20.4537 16.5944C19.8778 16.5944 19.692 16.9386 19.692 17.4546V18.9723C19.692 19.083 19.6053 19.1689 19.4938 19.1689C19.3823 19.1689 19.2956 19.083 19.2956 18.9723V16.4776C19.2956 16.3671 19.3823 16.281 19.4938 16.281C19.6053 16.281 19.692 16.3671 19.692 16.4776V16.6743C19.7972 16.4469 20.0945 16.2442 20.4971 16.2442C21.0051 16.2442 21.3331 16.4285 21.4818 16.6743C21.6367 16.4347 21.9093 16.2442 22.386 16.2442C23.3399 16.2442 23.5567 16.8095 23.5567 17.3318Z"
          fill={secondaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_7527_21740">
          <rect
            width="24"
            height="15.1456"
            fill="white"
            transform="translate(0 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ovh;
