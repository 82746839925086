import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";
import { getCSSVarString, ICON } from "Libs/themes";

const Leaf = ({ color }) => {
  const uid = useUniqueId();
  const fillColor = color ||"var(--icon-granite-fill,var(--icon-granite,var(--granite)))";

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`leaf-icon-${uid}`}
      style={{ pointerEvents: "none" }}
    >
      <title id={`leaf-icon-${uid}`}>Leaf icon</title>
      <path
        d="M2.8667 13.1333C3.00003 13.2667 3.13337 13.3333 3.33337 13.3333C3.53337 13.3333 3.6667 13.2667 3.80003 13.1333L5.00003 11.9333C5.60003 12.2 6.4667 12.4667 7.40003 12.4667C8.4667 12.4667 9.60003 12.1333 10.6667 11.1333C12.9334 8.86667 13.3334 3.6 13.3334 3.4C13.3334 3.2 13.2667 3 13.1334 2.86667C13 2.73333 12.8 2.66667 12.6 2.66667C12.4 2.66667 7.00003 3.2 4.8667 5.33333C3.53337 6.66667 3.33337 8.26667 3.60003 9.6L7.60003 7.4L2.80003 12.2C2.60003 12.4667 2.60003 12.8667 2.8667 13.1333Z"
        fill={fillColor}
      />
    </svg>
  );
};

Leaf.propTypes = {
  color: PropTypes.string
};

export default Leaf;
